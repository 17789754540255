@import "../Config/variables";

@media only screen and (max-width: 830px){

    .content{

        margin: 0 13vw !important;

        .welcome-title{
            font-size: 20px;
        }

        .card{
            .text{
                font-size: 14px;
            }
        }

        .result-title{
            font-size: 18px !important;
        }

        .result-container{
            flex-direction: column;
            text-align: center;

            & > *{
                width: 90%;
                height: 120px;
                margin-bottom: 15px;
                margin-left: 0px !important;
            }
        }
    }

}

.content{
    margin: 0 15vw;
    font-weight: 500;

    .welcome{
        display: flex;
        align-items: center;
        margin-top: 25px;

        .pic-conseille{
            border-radius: 50%;
            height: 50px;
            width: 50px;
            margin-right: 20px;
        }
    }

    .result-title{
        font-size: 22px;
        text-decoration: underline;
    }

    .informations-plafond{
        color: #969696;
    }

    .we-will-call-you{
        border-radius: 14px;
        background: #4986e020;
        display: flex;
        align-items: center;
    }

    .steps-title{
        font-size: 20px;
        text-decoration: underline;
    }

    .steps-container{
        display: flex;
        flex-direction: column;

        .step.colored{
            color: $accent500;
        }

        .step{
            display: flex;
            flex-direction: column;

            .first-line{
                display: flex;
                align-items: center;

                .number{
                    color: #fff;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                    flex-shrink: 0;
                }

                .number:not(.colored){
                    background-color: #000;
                }

                .number.colored{
                    background-color: $accent500;
                }

                font-weight: 600;
                font-size: 17px;
            }

            .second-line{
                margin-left: 45px;
                margin-bottom: 20px;
            }
        }
    }

    .result-container{
        display: flex;
        align-items: center;
         & > *{
             flex-grow: 1;
             flex-basis: 0;
             margin-left: 25px;
         }

         margin-bottom: 25px;
    }

    .result-range{
        p{
            color: $accent500;
            font-weight: 600;
            font-size: 17px;
            margin-bottom: 35px;
        }

        input{
            width: 100%;
            height: 20px;
            -webkit-appearance: none;
            background-color: #e7e7e7;
            border-radius: 10px;
        }
    }

    .main-color{
        color: $accent500;
    }
}

.MuiSlider-valueLabelOpen{
    background-color: rgba($color: #FFF, $alpha: 0.0) !important;
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.MuiSlider-sizeMedium{
    height: 9px !important;
}