@import "../../../Config/variables";

@media only screen and (max-width: 830px){
  .modal-phone{
    .h4{
      font-size: 20px !important;
    }

    .text-bad-phone{
      font-size: 14px !important;
    }

    .modal-content{
      min-width: 340px !important;

    }
    
  }

}

.modal-phone{
    
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      display: flex;
      background-color: rgba(0, 0, 0, 0.20);
      align-items: center;
      justify-content: center;
      z-index: 10;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    
      .modal-content {
        background-color: #fff;
        width: 70vw;
        max-width: 400px;
        height: 38vh;
        min-height: 370px;
        max-height: 400px;
        border-radius: 10px;
        border: 1px solid #d4d4d4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    
      input {
        border-radius: 8px;
        border-style: solid;
        border-color: #b3b3b3;
        padding: 10px;
      }
    
      h4 {
        font-size: 28px;
        margin: 0 10px;
        text-align: center;
        margin-bottom: 0;
      }
    
      .h4 {
        margin-top: 20px;
      }
    
      h5 {
        text-align: center;
      }
    
      button {
        margin-left: 5px;
        margin-right: 5px;
        border: 0;
        width: 80%;
        height: 60px;
        color: #fff;
        border-radius: 8px;
        text-align: center;
        margin-top: 10px;
        transition: 300ms;
        font-weight: 600;
        cursor: pointer;
    
        &:hover:not(&.disable) {
          background-color: $accent400;
        }
    
        &.mb-10 {
          margin-bottom: 10px;
        }
    }

    button.colored{
        background-color: $accent500;
    }

    button.grey{
        background-color: #969696;
    }


    .title-bad-phone{
        margin-bottom: 25px;
        font-size: 25px;
    }

    .active{
        background-color: $accent500;
    }

    .disable{
        background-color: #b3b3b3;
    }

    .text-bad-phone{
        padding: 0 10px;
        text-align: center;
        margin-top: 10px;

        & > span{
            color: $accent500;
            font-weight: 600;
        }
    }

    .bad-token{
        margin-top: 8px;
        margin-bottom: 5px;
        color: red;
        font-weight: 600;
        font-size: 14px;
    }
}